import { Component, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class AppService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }


}
