import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { Ng5SliderModule } from 'ng5-slider';
import { SlideshowModule } from 'ng-simple-slideshow';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { PuppyhoodComponent } from './puppyhood/puppyhood.component';
import { BeachComponent } from './beach/beach.component';
import { CalendarsComponent } from './calendars/calendars.component';
import { FriendsComponent } from './friends/friends.component';
import { VideoComponent } from './video/video.component';
import { ContactComponent } from './contact/contact.component';
import { UrlNotFoundComponent } from './url-not-found/url-not-found.component'
import { AppService } from './app.service';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    PuppyhoodComponent,
    BeachComponent,
    CalendarsComponent,
    FriendsComponent,
    VideoComponent,
    ContactComponent,
    UrlNotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: '*', component: HomeComponent, pathMatch: 'full' },
      { path: 'puppyhood', component: PuppyhoodComponent },
      { path: 'beach', component: BeachComponent },
      { path: 'calendars', component: CalendarsComponent },
      { path: 'friends', component: FriendsComponent },
      { path: 'video', component: VideoComponent },
      { path: 'contact', component: ContactComponent },
      { path: '**', component: UrlNotFoundComponent },
    ]),
    Ng5SliderModule,
    SlideshowModule
  ],
  providers: [
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
