import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IImage } from '../../../node_modules/ng-simple-slideshow/src/app/modules/slideshow/IImage';

@Component({
  selector: 'app-beach',
  templateUrl: '../home/home.component.html',
  styleUrls: ['../home/home.component.css']
})
export class BeachComponent {

  width: number = 1000;
  height: string = "750px";
  autoPlay: boolean = true;

  imageUrlArray: IImage[] = [
    { url: "http://coopersdomain.com/assets/images/beach/ByTheSea.jpg", caption: "" },
    { url: "http://coopersdomain.com/assets/images/beach/CoopAndDoriaAtCorgiCon.jpg", caption: "At Corgi Con" },
    { url: "http://coopersdomain.com/assets/images/beach/DoriaAndCooperAtTheBeach1.jpg", caption: "" },
    { url: "http://coopersdomain.com/assets/images/beach/BeachSilhouette3.jpg", caption: "" },
    { url: "http://coopersdomain.com/assets/images/beach/OnTheBeach1.jpg", caption: "" },
    { url: "http://coopersdomain.com/assets/images/beach/SideShot.jpg", caption: "" },
    { url: "http://coopersdomain.com/assets/images/beach/ByTheSea2.jpg", caption: "" },
  ];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {}

  toggleAutoPlay() {
    this.autoPlay = !this.autoPlay;
  }

  getButtonClass() {
    return this.autoPlay ? "fa-pause" : "fa-play";
  }
}

