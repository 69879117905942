import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Player from '@vimeo/player'
import { videoModel } from '../models/models';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})

export class VideoComponent {

  @ViewChild('playerContainer1') playerContainer1
  @ViewChild('playerContainer2') playerContainer2
  @ViewChild('playerContainer3') playerContainer3
  @ViewChild('playerContainer4') playerContainer4
  @ViewChild('playerContainer5') playerContainer5
  @ViewChild('playerContainer6') playerContainer6
  @ViewChild('playerContainer7') playerContainer7
  @ViewChild('playerContainer8') playerContainer8
  @ViewChild('playerContainer9') playerContainer9

  private players: Player[] = [];
  selectedIndex: number = -1;

  videos: videoModel[] = [
    { "description": "Alfie's litter", "url": "https://vimeo.com/909867932" },
  ];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) { }

  ngAfterViewInit() {
    this.players.push(new Player(this.playerContainer1.nativeElement));
    this.players.push(new Player(this.playerContainer2.nativeElement));
    this.players.push(new Player(this.playerContainer3.nativeElement));
    this.players.push(new Player(this.playerContainer4.nativeElement));
    this.players.push(new Player(this.playerContainer5.nativeElement));
    this.players.push(new Player(this.playerContainer6.nativeElement));
    this.players.push(new Player(this.playerContainer7.nativeElement));
    this.players.push(new Player(this.playerContainer8.nativeElement));
    this.players.push(new Player(this.playerContainer9.nativeElement));

    var width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    let height = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;

    if (height < 750) {
      this.players.forEach(player => {
        player.nativeElement.width = 960;
        player.nativeElement.height = 540;
      });
    }
    else if (height < 850) {
      this.players.forEach(player => {
        player.nativeElement.width = 1120;
        player.nativeElement.height = 630;
      });
    }

    //this.player.nativeElement.src = this.videos[0].url;
    //this.player.nativeElement.loadingSpinner = false;
  }

  selectVideo(index: number) {
    this.selectedIndex = index;
    this.players[this.selectedIndex].play();

    //this.player.nativeElement.src = this.videos[index].url;
    //this.player.nativeElement.load();
    //this.player.nativeElement.play();
  }

  goBack() {
    this.selectedIndex = -1;
    this.players[0].pause();
    this.players[1].pause();
    this.players[2].pause();
    this.players[3].pause();
    this.players[4].pause();
    this.players[5].pause();
    this.players[6].pause();
    this.players[7].pause();
    this.players[8].pause();
  }
}
