import { Component, ViewChild } from '@angular/core';

import { Options } from 'ng5-slider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  slideshowSpeed: number = 3;

  setSlideshowSpeed() {

  }




  colorOption: number = 1;

  grayValue: number = 100;
  redValue: number = 0;
  greenValue: number = 0;
  blueValue: number = 0;

  grayBgValue: number = 100;
  redBgValue: number = 0;
  greenBgValue: number = 0;
  blueBgValue: number = 0;
  bgColor: string = "rgb(255,255,255)";

  grayTextValue: number = 0;
  redTextValue: number = 0;
  greenTextValue: number = 0;
  blueTextValue: number = 50;
  textColor: string = "rgb(0, 0, 128)";

  options: Options = {
    floor: 0,
    ceil: 100
  };

  setColor() {
    this.setOptionColor(this.colorOption);
  }

  setOptionColor(option: number) {
    let rgbRed: number = (this.redValue * 255 / 100);
    let rgbGreen: number = this.greenValue * 255 / 100;
    let rgbBlue: number = this.blueValue * 255 / 100;
    let maxColor: number = Math.max(rgbRed, rgbGreen, rgbBlue);
    let grayValue: number = (255 - maxColor) * this.grayValue / 100;
    rgbRed += grayValue;
    rgbGreen += grayValue;
    rgbBlue += grayValue;
    if (option == 1) {
      this.bgColor = "rgb(" + rgbRed.toString() + "," + rgbGreen.toString() + "," + rgbBlue.toString() + ")";
    } else {
      this.textColor = "rgb(" + rgbRed.toString() + "," + rgbGreen.toString() + "," + rgbBlue.toString() + ")";
    }
  }

  setMode(option) {
    if (option == 1) {
      this.redTextValue = this.redValue;
      this.greenTextValue = this.greenValue;
      this.blueTextValue = this.blueValue;
      this.grayTextValue = this.grayValue;
      this.redValue = this.redBgValue;
      this.greenValue = this.greenBgValue;
      this.blueValue = this.blueBgValue;
      this.grayValue = this.grayBgValue;
    } else {
      this.redBgValue = this.redValue;
      this.greenBgValue = this.greenValue;
      this.blueBgValue = this.blueValue;
      this.grayBgValue = this.grayValue;
      this.redValue = this.redTextValue;
      this.greenValue = this.greenTextValue;
      this.blueValue = this.blueTextValue;
      this.grayValue = this.grayTextValue;
    }
  }

  reset() {
    this.colorOption = 1;
    this.grayValue = 0;
    this.redValue = 0;
    this.greenValue = 0;
    this.blueValue = 50;
    this.setOptionColor(2);
    this.grayValue = 100;
    this.redValue = 0;
    this.greenValue = 0;
    this.blueValue = 0;
    this.grayBgValue = 100;
    this.redBgValue = 0;
    this.greenBgValue = 0;
    this.blueBgValue = 0;
    this.grayTextValue = 100;
    this.redTextValue = 0;
    this.greenTextValue = 0;
    this.blueTextValue = 50;
    this.setOptionColor(1);
  }
}
