import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-url-not-found',
  templateUrl: './url-not-found.component.html',
  styleUrls: ['./url-not-found.component.css']
})
export class UrlNotFoundComponent implements OnInit {
 
  constructor(public router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
