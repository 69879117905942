import { Component, Output, EventEmitter } from '@angular/core';

import { IImage } from '../../../node_modules/ng-simple-slideshow/src/app/modules/slideshow/IImage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  width: number = 1000;
  height: string = "750px";
  autoPlay: boolean = true;

  imageUrlArray: IImage[] = [
    { url: "http://alfiesdomain.com/assets/images/home/BabyPic1.jpg", caption: "4 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/BabyPic2.jpg", caption: "6 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/BabyPic4.jpg", caption: "6 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup2.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup4.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup5.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup6.jpg", caption: "8 weeks old" },
    //{ url: "http://alfiesdomain.com/assets/images/home/Pickup7.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup8.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup9.jpg", caption: "8 weeks old" },
    //{ url: "http://alfiesdomain.com/assets/images/home/Pickup10.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/EarlyHome1.jpg", caption: "Exploring the backyard" },
    { url: "http://alfiesdomain.com/assets/images/home/EarlyHome2.jpg", caption: "Exploring the backyard" },
    { url: "http://alfiesdomain.com/assets/images/home/Leo.jpg", caption: "Alfie's father Leo" },
    { url: "http://alfiesdomain.com/assets/images/home/Megs.jpg", caption: "Alfie's mother Megs" },
    { url: "http://alfiesdomain.com/assets/images/home/AlfieFinnMeetup1.jpg", caption: "Hanging out with next door neighbor Finn" },
    { url: "http://alfiesdomain.com/assets/images/home/AlfieFinnMeetup2.jpg", caption: "Hanging out with next door neighbor Finn" },
  ];

  toggleAutoPlay() {
    this.autoPlay = !this.autoPlay;
  }

  getButtonClass() {
    return this.autoPlay ? "fa-pause" : "fa-play";
  }
}

