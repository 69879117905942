import { Component, Inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IImage } from '../../../node_modules/ng-simple-slideshow/src/app/modules/slideshow/IImage';

@Component({
  selector: 'app-calendars',
  templateUrl: './calendars.component.html',
  styleUrls: ['./calendars.component.css']
})
export class CalendarsComponent {
  width: number = 1000;
  height: string = "750px";
  autoPlay: boolean = true;

  imageUrlArray: IImage[] = [];

  @ViewChild('slideshow') slideshow: any;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
  }

  toggleAutoPlay() {
    this.autoPlay = !this.autoPlay;
  }

  getButtonClass() {
    return this.autoPlay ? "fa-pause" : "fa-play";
  }s

  ngOnInit() {
    this.setCalendar(2015);
  }

  setCalendar(year) {
    switch (year) {
      case 2015:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015January.jpg", caption: "January, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015February.jpg", caption: "February, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015March.jpg", caption: "March, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015April.jpg", caption: "April, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015May.jpg", caption: "May, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015June.jpg", caption: "June, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015July.jpg", caption: "July, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015August.jpg", caption: "August, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015September.jpg", caption: "September, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015October.jpg", caption: "October, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015November.jpg", caption: "November, 2015" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2015/2015December.jpg", caption: "December, 2015" },
        ]
        break;

      case 2016:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016January.jpg", caption: "January, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016February.jpg", caption: "February, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016March.jpg", caption: "March, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016April.jpg", caption: "April, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016May.jpg", caption: "May, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016June.jpg", caption: "June, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016July.jpg", caption: "July, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016August.jpg", caption: "August, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016September.jpg", caption: "September, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016October.jpg", caption: "October, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016November.jpg", caption: "November, 2016" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2016/2016December.jpg", caption: "December, 2016" },
        ]
        break;

      case 2017:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017January.jpg", caption: "January, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017February.jpg", caption: "February, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017March.jpg", caption: "March, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017April.jpg", caption: "April, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017May.jpg", caption: "May, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017June.jpg", caption: "June, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017July.jpg", caption: "July, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017August.jpg", caption: "August, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017September.jpg", caption: "September, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017October.jpg", caption: "October, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017November.jpg", caption: "November, 2017" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2017/2017December.jpg", caption: "December, 2017" },
        ]
        break;

      case 2018:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018January.jpg", caption: "January, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018February.jpg", caption: "February, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018March.jpg", caption: "March, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018April.jpg", caption: "April, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018May.jpg", caption: "May, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018June.jpg", caption: "June, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018July.jpg", caption: "July, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018August.jpg", caption: "August, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018September.jpg", caption: "September, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018October.jpg", caption: "October, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018November.jpg", caption: "November, 2018" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2018/2018December.jpg", caption: "December, 2018" },
        ]
        break;

      case 2019:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019January.jpg", caption: "January, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019February.jpg", caption: "February, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019March.jpg", caption: "March, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019April.jpg", caption: "April, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019May.jpg", caption: "May, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019June.jpg", caption: "June, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019July.jpg", caption: "July, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019August.jpg", caption: "August, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019September.jpg", caption: "September, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019October.jpg", caption: "October, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019November.jpg", caption: "November, 2019" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2019/2019December.jpg", caption: "December, 2019" },
        ]
        break;

      case 2020:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Jan.jpg", caption: "January, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Feb.jpg", caption: "February, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Mar.jpg", caption: "March, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Apr.jpg", caption: "April, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020May.jpg", caption: "May, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Jun.jpg", caption: "June, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Jul.jpg", caption: "July, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Aug.jpg", caption: "August, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Sep.jpg", caption: "September, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Oct.jpg", caption: "October, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Nov.jpg", caption: "November, 2020" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2020/2020Dec.jpg", caption: "December, 2020" },
        ]
        break;

      case 2021:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Jan.jpg", caption: "January, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Feb.jpg", caption: "February, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Mar.jpg", caption: "March, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Apr.jpg", caption: "April, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021May.jpg", caption: "May, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Jun.jpg", caption: "June, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Jul.jpg", caption: "July, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Aug.jpg", caption: "August, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Sep.jpg", caption: "September, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Oct.jpg", caption: "October, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Nov.jpg", caption: "November, 2021" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2021/2021Dec.jpg", caption: "December, 2021" },
        ]
        break;

      case 2022:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Jan.jpg", caption: "January, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Feb.jpg", caption: "February, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Mar.jpg", caption: "March, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Apr.jpg", caption: "April, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022May.jpg", caption: "May, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Jun.jpg", caption: "June, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Jul.jpg", caption: "July, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Aug.jpg", caption: "August, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Sep.jpg", caption: "September, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Oct.jpg", caption: "October, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Nov.jpg", caption: "November, 2022" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2022/2022Dec.jpg", caption: "December, 2022" },
        ]
        break;

      case 2023:
        this.imageUrlArray = [
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Jan.jpg", caption: "January, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Feb.jpg", caption: "February, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Mar.jpg", caption: "March, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Apr.jpg", caption: "April, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023May.jpg", caption: "May, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Jun.jpg", caption: "June, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Jul.jpg", caption: "July, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Aug.jpg", caption: "August, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Sep.jpg", caption: "September, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Oct.jpg", caption: "October, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Nov.jpg", caption: "November, 2023" },
          { url: "http://coopersdomain.com/assets/images/calendars/months/2023/2023Dec.jpg", caption: "December, 2023" },
        ]
        break;

    }
  }


}

