import { Component } from '@angular/core';

import { IImage } from '../../../node_modules/ng-simple-slideshow/src/app/modules/slideshow/IImage';

@Component({
  selector: 'app-puppyhood',
  templateUrl: '../home/home.component.html',
  styleUrls: ['../home/home.component.css']
})
export class PuppyhoodComponent {

  width: number = 1000;
  height: string = "750px";
  autoPlay: boolean = true;

  imageUrlArray: IImage[] = [
    { url: "http://alfiesdomain.com/assets/images/home/BabyPic1.jpg", caption: "4 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/BabyPic2.jpg", caption: "6 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/BabyPic4.jpg", caption: "6 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup2.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup4.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup5.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup6.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup7.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup8.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup9.jpg", caption: "8 weeks old" },
    { url: "http://alfiesdomain.com/assets/images/home/Pickup10.jpg", caption: "8 weeks old" },  ];

  toggleAutoPlay() {
    this.autoPlay = !this.autoPlay;
  }

  getButtonClass() {
    return this.autoPlay ? "fa-pause" : "fa-play";
  }
}
